const groups = [
  {
    key: "insurance",
    label: "Страхование",
    icon: "all_inclusive",
    limitedAccess: false,
    expanded: true,
    links: []
  },
  {
    key: "directory",
    label: "Справочники",
    icon: "list",
    limitedAccess: true,
    access: 'DICTIONARIES',
    expanded: false,
    links: []
  },
  {
    key: "reports",
    label: "Отчеты",
    icon: "insert_chart_outlined",
    expanded: false,
    limitedAccess: false,
    links: []
  },
  {
    key: "settings",
    label: "Настройки",
    icon: "settings",
    limitedAccess: false,
    expanded: false,
    links: []
  }
];

export default function navigationFilter(routes) {
  let arr = [];
  groups.forEach(group => {
    group.links = routes.filter(r => r.meta.group === group.key);
  });
  // groups.forEach(g => {
  //   if (g.links.length > 0 && (!g.limitedAccess || localStorage.getItem('userAccess').split(',').find(a => a === g.access))) arr.push(g);
  // });

  const currentUserHasAccess = (accessName) => {
    return localStorage.getItem('userAccess').split(",").find(entry => {
      const accessRights = entry.split(":")
      return accessRights[0] === accessName && accessRights[1] !== '---'
    }) !== undefined;
  }

  groups.filter(g => g.links.length > 0 && (!g.limitedAccess || currentUserHasAccess(g.access))).forEach(g => arr.push(g))
  console.log('NAVIGATION GROUPS: ', arr)
  return arr;
}
