import ctx from "@/ctx";
import fetch from "../../utils/fetch";

let Item = function(d) {
  let self = this;

  self.id = d.id;
  self.fullName = d.fullName ? d.fullName : "загрузка...";
  self.typeKey = d.typeKey;
  self.cityKey = d.cityKey;
  self.deleted = d.deleted;
  // self.awaiting = true;
};

Item.prototype.awaiting = true;

let Items = function(d) {
  let self = this;

  self.items = [];

  self.first = function(id) {
    if (!id) return;
    let res = self.items.filter(i => {
      return i.id === id;
    });

    if (res.length > 0) return res[0];

    self.items.push(new Item({ id: id }));
    getFirst(id);
    return getAwaiting(id);
  };

  self.byType = function(typeKeys) {
    let isArray = Array.isArray(typeKeys);
    return self.items.filter(i => {
      if (isArray) {
        let match = false;
        for (let k in typeKeys) {
          if (i.typeKey === typeKeys[k]) {
            match = true;
            break;
          }
        }
        return match;
      } else return i.typeKey === typeKeys;
    });
  };

  self.activeByType = function(typeKeys) {
    let isArray = Array.isArray(typeKeys);
    return self.items.filter(i => {
      if (isArray) {
        let match = false;
        for (let k in typeKeys) {
          if (i.typeKey === typeKeys[k] && !i.deleted) {
            match = true;
            break;
          }
        }
        return match;
      } else return i.typeKey === typeKeys && !i.deleted;
    });
  };

  self.push = function(d) {
    self.first(d);
  };

  self.limitOrganizationServices = async function(id) {
    await fetch.post("/api/org/limit-organization-services?id=" + id);
  };

  self.cancelServiceLimitation = async function(id) {
    await fetch.post(
      "/api/org/cancel-services-limitation?organizationId=" + id
    );
  };

  self.getLimited = async function() {
    const res = await fetch.get(
      "/api/org/get-organizations-with-limited-services-ids"
    );
    return res;
  };

  self.list = async function() {
    return await fetch.get("api/src/org/get-list");
  };

  self.get = function(id) {
    return self.items.find(i => i.id === id);
  };

  async function getFirst(id) {
    const res = await fetch.get("/api/src/org/get-first?id=" + id);
    let item = getAwaiting(id);
    item.awaiting = false;
    if (res) {
      item.fullName = res.fullName;
      item.typeKey = res.typeKey;
      item.deleted = res.deleted
    } else item.fullName = "n/a";
  }

  function getAwaiting(id) {
    return self.items.find(i => {
      return i.id === id && i.awaiting;
    });
  }

  d.forEach(d => {
    let item = new Item(d);
    item.awaiting = false;
    self.items.push(item);
  });
};

export default async () => {
  console.log("ctx.src.organizations: loaded");
  const res = await fetch.get("/api/src/org/get-list");
  ctx.src.org = new Items(res);

  console.log("ctx.src.organizations: initialized");
};
